@charset "utf-8";
/*
Template Name: Jeren
Author: <a href="https://www.os-templates.com/">OS Templates</a>
Author URI: https://www.os-templates.com/
Licence: Free to use under our free template licence terms
Licence URI: https://www.os-templates.com/template-terms
File: Custom Flexslider CSS => For FlexSlider v2.6.3
*/

/* Browser Resets
--------------------------------------------------------------------------------------------------------------- */
.flex-container a:hover, .flex-slider a:hover{outline:none;}
.slides, .slides > li, .flex-control-nav, .flex-direction-nav{margin:0; padding:0; list-style:none;}
.flex-direction-nav a{font-family:"FontAwesome";}


/* Necessary Styles
--------------------------------------------------------------------------------------------------------------- */
.flexslider{display:block; position:relative; margin:0; padding:0;}
.flexslider .slides{display:block; width:100%; overflow:hidden;}
.flexslider .slides > li{display:none; -webkit-backface-visibility:hidden;}/* Hide the slides before the JS is loaded. Avoids image jumping */
.flexslider .slides a{text-decoration:none; outline:none;}
.flexslider .slides a img{border:none;}
.flexslider .slides img{display:block; max-width:100%; margin:0 auto;}
/* Modern Clear fix for the .slides element */
.slides::before, .slides::after{display:table; content:"";}
.slides, .slides::after{clear:both;}


/* Default Theme
--------------------------------------------------------------------------------------------------------------- */
.flex-viewport{max-height:2000px; transition:all 1s ease;}
.loading .flex-viewport{max-height:300px;}
.flexslider.carousel .slides li{margin-right:20px;}/* Must be the same as in the javascript */

/* Direction Nav */
.flex-direction-nav{}
.flex-direction-nav a{display:block; position:absolute; top:50%; width:40px; height:40px; margin:-20px 0 0 0; z-index:10; overflow:hidden; opacity:0; cursor:pointer; text-decoration:none;}
.flex-direction-nav a::before{display:inline-block; font-size:40px; line-height:1; content:"\f053"; z-index:999;}
.flex-direction-nav a.flex-next::before{content:"\f054";}
.flex-direction-nav .flex-prev{left:0;}
.flex-direction-nav .flex-next{right:0;}
.flexslider:hover .flex-prev, .flexslider:hover .flex-next{opacity:.5;}
.flexslider:hover .flex-prev:hover, .flexslider:hover .flex-next:hover{opacity:1;}
.flex-direction-nav .flex-disabled{visibility:hidden; opacity:0; cursor:default; z-index:-1;}

/* Control Nav */
.flex-control-nav{display:block; position:absolute; bottom:-80px; width:100%; text-align:center;}
.flex-control-nav li{display:inline-block; margin:0 5px;}
.flex-control-paging li a{display:block; width:11px; height:11px; cursor:pointer; text-indent:-9999px; border:2px solid; border-radius:20px;}
.flex-control-paging li a.flex-active{cursor:default;}


/* Responsive
--------------------------------------------------------------------------------------------------------------- */
@media screen and (max-width:650px){
	.flex-direction-nav .flex-prev, .flex-direction-nav .flex-next{display:none;}
}


/* Colours
--------------------------------------------------------------------------------------------------------------- */
.flex-direction-nav a{color:rgba(255,255,255,1);}
.flex-control-paging li a{background-color:rgba(255,255,255,1); border-color:rgba(255,255,255,0);}
.flex-control-paging li a.flex-active{background-color:rgba(255,255,255,0); border-color:rgba(255,255,255,1);}